import React from "react";

const Footer =() =>{
    return(
        <div className="max-w-[1200px] sm;h-[150px] p-12 flex justify-between mx-auto">
            <span className="primary-color">Pooja Pant</span>
            <p className="text-gray-600">poozapant333@gmail.com <br/> #webdev2024</p>

        </div>
    )
}
export default Footer;
